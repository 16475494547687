import React, { useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { useLocalStorage } from 'usehooks-ts';

const Logout: React.FC = () => {
  const [, setLoggedIn] = useLocalStorage('loggedIn', false);

  useEffect(() => {
    setLoggedIn(false);
  });

  return (
    <div>
        <Redirect to="/" />
    </div>
  );
};

export default Logout;