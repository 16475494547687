import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import './Category.css';

interface SubcategoryProps extends RouteComponentProps {
    subcategories?: any;
    songs?: any;
}

const Subcategory: React.FC<SubcategoryProps> = ({match, songs, subcategories}) => {
    const [subcategory, setSubcategory] = useState<any>(null);
    const [songsFiltered, setSongsFiltered] = useState<Array<any>>();
    

    useEffect(() => {

        let params : any = match.params;
        if (params.id && subcategory === null && subcategories) {
            setSubcategory(subcategories.find((c: { id: number; }) => c.id === Number(params.id)));
        }

    }, [subcategory, match, subcategories]);

    useEffect(() => {
        if (subcategory && songs && songs.length) {
            setSongsFiltered(songs.sort((a: { name: number; },b: { name: number; }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).filter((s: any) => (s.subcategory_id === subcategory.id)));
        }
    }, [songs, subcategory]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {subcategory && <IonTitle>{subcategory.name}</IonTitle>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {subcategory && songsFiltered && songsFiltered.map((song: { id: any; category_id: any; name: boolean | null | undefined; }, i: React.Key) => {
                    return (
                        <IonItem key={i} button={true} href={`/songs/${song.id}`} style={{borderLeft: subcategory && `10px solid ${subcategory.color}`}}>
                            <IonLabel>{song.name}</IonLabel>
                        </IonItem>
                    );
                })}
            </IonContent>
        </IonPage>
    );
};

export default withRouter(Subcategory);
