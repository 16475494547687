import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonSelect, IonSelectOption, IonButton, IonInput } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import API from '../api';

const chordMapping: { [key: string]: string; } = {
    "Bm": "Hm",
    "B": "H",
    "B♭": "B ",
    "B♭m": "Bm "
}

interface SongaddProps extends RouteComponentProps {
    categories?: any;
    subcategories?: any;
}

const Songadd: React.FC<SongaddProps> = ({history, location, categories, subcategories}) => {

    const [song, setSong] = useState<any>(null);
    const [name, setName] = useState<string>("");
    const [editing, setEditing] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState<any>(null);
    const [subcategoriesFiltered, setSubcategoriesFiltered] = useState<Array<any>>();

    useEffect(() => {
        if(categories && categories.length !== 0) {
            if(location.search) {
                setEditing(true);
                const search = location.search;
                const params = new URLSearchParams(search);
                const song_id : number = Number(params.get('usid'));
                const song_name : string | null = params.get('usname');
                if (song_name) setName(song_name);
                const song_category : number | null = Number(params.get('uscategoryid'));
                if (selectedCategory === null) setSelectedCategory(song_category);
                setSong({
                    ...song,
                    id: song_id,
                    name: song_name,
                    category_id: song_category
                });
            } else {
                if (selectedCategory === null) setSelectedCategory(1);
            }    
        }
    }, [location, categories, selectedCategory]);

    useEffect(() => {
        if (subcategories && selectedCategory) {
            setSubcategoriesFiltered(subcategories.filter((s: any) => (s.category_id === selectedCategory)));
        }
    }, [selectedCategory, subcategories]);

    const showFile = async (e: any) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = async (e: any) => { 
            const text = (e.target.result);
            const lines = text.split('\n');
            const songTitle = getElemValue(lines.find((l: string | string[]) => l.includes(`<span class='title_Chordastic'>`))).replace(/(\r\n|\n|\r)/gm, "");
            let contentString = `[`;
            const firstLineIndex = lines.indexOf(lines.find((l: string | string[]) => l.includes(`<span class='info_Chordastic'>`))) + 4; // skip the span with general info
            const lastLineIndex = lines.indexOf(lines.find((l: string | string[]) => l.includes(`Created by Chordastic Software`)));
            let chordLine = false;
            for (const i in lines) {
                if (i >= firstLineIndex && i < lastLineIndex) {
                    let line = lines[i].replace(/(\r\n|\n|\r)/gm, "");

                    let newPart = '';
                    if (line.includes(`<span class='note_chordastic'>`)) {
                        newPart = `{ "chords": ${parseChords(line)}, `;
                        chordLine = true;
                    } else {
                        if (chordLine) {
                            newPart = ` "text": "${escapeText(line)}"},`;
                        } else {
                            newPart = `{ "chords": [], "text": "${escapeText(line)}"},`;
                        }
                        chordLine = false;
                    }
                    contentString += newPart;
                }
            }
            contentString = contentString.replace(/,\s*$/, "");
            contentString += `]`;
            setSong({
                ...song,
                name: songTitle,
                content: JSON.parse(contentString)
            });
        };
    }

    const getElemValue = (elStr: string) => {
        let startIdx = elStr.indexOf('<');
        let endIdx = elStr.indexOf('>');
        let parsedStr = elStr;
        let htmlToReplace = elStr.substring(startIdx, endIdx + 1);
        do {
            parsedStr = parsedStr.replace(htmlToReplace, '');
            startIdx = parsedStr.indexOf('<');
            endIdx = parsedStr.indexOf('>');
            htmlToReplace = parsedStr.substring(startIdx, endIdx + 1);
        } while (startIdx !== -1 && endIdx !== -1 && htmlToReplace);
        return `${parsedStr.trim()}`;
    }

    const replaceChords = (chord: string) : any  => {
        if (!chord) return ``;
        const isComposedChord = chord.indexOf('/') !== -1;
        if (isComposedChord) { // composed chord includes slash eg. Bm/A and two chords - root and bass
            let composedChordStr : string = `${replaceChords(chord.split('/')[0])}/${replaceChords(chord.split('/')[1])}`;
            const spaceCount = (composedChordStr.match(/ /g) || []).length;
            if (spaceCount > 0) { // move the space to the end of the composed chord
                composedChordStr = composedChordStr.replace(/ /g, '');
                composedChordStr = composedChordStr + new Array(spaceCount).fill(' ').join('');
                return composedChordStr;
            } else return `${replaceChords(chord.split('/')[0])}/${replaceChords(chord.split('/')[1])}`;
        }
        if (Object.keys(chordMapping).indexOf(chord) !== -1) {
            return `${chordMapping[chord]}`;
        }
        return `${chord}`;
    }

    const parseChords = (chordStr: string) => {
        let parsedStr = chordStr;
        let indexOfChordStart = parsedStr.indexOf(`<span class='note_chordastic'>`);
        let indexOfChordEnd = parsedStr.indexOf(`</span>`);
        const endSpanLength = 7;
        let htmlToReplace = parsedStr.substring(indexOfChordStart, indexOfChordEnd + endSpanLength);

        do {
            parsedStr = parsedStr.replace(htmlToReplace, getElemValue(htmlToReplace));
            indexOfChordStart = parsedStr.indexOf(`<span class='note_chordastic'>`);
            indexOfChordEnd = parsedStr.indexOf(`</span>`);
            htmlToReplace = parsedStr.substring(indexOfChordStart, indexOfChordEnd + endSpanLength);
        } while (indexOfChordStart !== -1 && indexOfChordEnd !== -1 && htmlToReplace);

        const chords = parsedStr.split(/(\s+)/).filter( e => e.trim().length > 0);
        let jsonChords = `[`;
        chords.forEach((ch, i) => {
            let prevP = parsedStr.indexOf(chords[i - 1]);
            let prevChordLength = chords[i - 1] ? chords[i - 1].length : 0;
            let p = parsedStr.indexOf(ch, prevP + prevChordLength);
            jsonChords += `{"chord": "${replaceChords(ch)}", "pos": ${p}},`;
        });
        jsonChords = jsonChords.replace(/,\s*$/, "");
        return `${jsonChords}]`;
    }

    const escapeText = (text: string) => {
        if (!text.trim()) return ``;
        else return `${text.replace(/\"/g, '\\"')}`;
    }

    const addSong = () => {
        if (song.category_id) {
            if (editing) {
                if (name) song.name = name;
                API.post("/update/song", song)
                .then((res: any) => {
                    if (res.status === 200) {
                        setTimeout(()=>{
                            window.location.href = `/songs/${song.id}`;
                        }, 0);
                    }
                   
                })
                .catch((error: any)=>{
                  
                })
            } else {
                API.post("/add/song", song)
                .then((res: any) => {
                   if (res.status === 200) {
                    const url = song.subcategory_id ? `/subcategories/${song.subcategory_id}` : `/categories/${song.category_id}`;
                    setTimeout(()=>{
                        window.location.href = url;
                    }, 0);
                   }
                })
                .catch((error: any)=>{
                  
                })
            }
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{`${song?.id ? 'Upraviť pieseň "' + song?.name + '"' : 'Pridať pieseň'}`}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList>
                    {editing && <IonItem>
                        <IonInput value={name} onIonChange={e => setName(e.detail.value!)} placeholder='Názov piesne'></IonInput>
                    </IonItem>}
                    <IonItem>
                        <IonSelect placeholder="Vyberte kategóriu"
                            value={selectedCategory}
                            onIonChange={(e) => {setSong({ ...song, category_id: e.detail.value }); setSelectedCategory(e.detail.value)}}>
                        {categories && categories.map((category: { color: any; id: any; name: any; }, i: React.Key | null | undefined) => {
                            return (
                                <IonSelectOption value={category.id} key={category.id}>{category.name}</IonSelectOption>
                            );
                        })}
                        </IonSelect>
                    </IonItem>
                    { subcategoriesFiltered && subcategoriesFiltered.length !== 0 && <IonItem>
                        <IonSelect placeholder="Vyberte podkategóriu"
                            value={selectedSubcategory}
                            onIonChange={(e) => {setSong({ ...song, subcategory_id: e.detail.value }); setSelectedSubcategory(e.detail.value)}}>
                        {subcategoriesFiltered && subcategoriesFiltered.map((subcategory: { color: any; id: any; name: any; }, i: React.Key | null | undefined) => {
                            return (
                                <IonSelectOption value={subcategory.id} key={subcategory.id}>{subcategory.name}</IonSelectOption>
                            );
                        })}
                        </IonSelect>
                    </IonItem> }
                </IonList>
                <div style={{padding: "10px"}}><input type="file" onChange={(e) => showFile(e)} /></div>
                <IonButton onClick={() => { addSong() }}>{`${editing ? 'Upraviť pieseň' : 'Pridať pieseň'}`}</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default withRouter(Songadd);