import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonDatetime, IonInput, IonList, IonItemDivider, IonItem, IonButton } from '@ionic/react';
import API from '../api';
import { RouteComponentProps, withRouter } from 'react-router';


// curl -X POST http://localhost:3000/api/add/playlist -H 'Content-Type: application/json' -d '{"name": "Test máj", "date": "2023-05-02T20:00:00+02:00", "user_id": 1, "songs_id": []}'
interface PlaylistaddProps extends RouteComponentProps {
    
}

const Playlistadd: React.FC<PlaylistaddProps> = ({location, history}) => {
    const [name, setName] = useState<string>("");
    const [date, setDate] = useState<any>();
    const [songs_id, setSongs_id] = useState<Array<number>>([]);

    useEffect(() => {
        if(location.search) {
            const search = location.search;
            const params = new URLSearchParams(search);
            const song_id : Array<number> = [Number(params.get('songid'))];
            setSongs_id(song_id);
        }
    }, [location]);

    const addPlaylist = () => {
        if (name && date) {
            API.post("/add/playlist", {
                name,
                date,
                user_id: 1, 
                songs_id
              })
            .then((res: any) => {
                if (res.status === 200) {
                    history.push("/playlists?refresh=true");
                }
            })
            .catch((error: any)=>{
              
            })
        }
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonList>
                    <IonItemDivider>Názov</IonItemDivider>
                    <IonItem>
                        <IonInput value={name} onIonChange={e => setName(e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItemDivider>Dátum</IonItemDivider>
                    <IonItem>
                        <IonDatetime locale="sk-SK" firstDayOfWeek={1} hourCycle="h23" value={date} onIonChange={e => setDate(e.detail.value!)}></IonDatetime>
                    </IonItem>
                    <IonButton expand="full" onClick={addPlaylist}>Vytvoriť</IonButton>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default withRouter(Playlistadd);