import React, { useState } from 'react';
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonSearchbar, SearchbarChangeEventDetail } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';

import './Songs.css';
// import songs from '../songs.json';
interface SongsProps extends RouteComponentProps {
  categories?: Array<any>;
  songs?: Array<any>;
}

const Search: React.FC<SongsProps> = ({songs, categories}) => {
  const [searchText, setSearchText] = useState('');

  const getCategoryColor = (categoryId: any) => {
    return categories && categories.find(c => c.id === categoryId).color;
  }

  const searchAction = (event: CustomEvent<SearchbarChangeEventDetail>) => {
    setSearchText(event.detail.value!);
  }

  return (
    <IonPage>
      {<IonHeader>
        <IonToolbar>
          <IonSearchbar style={{width: 'calc(100% - 65px)'}} value={searchText} onIonChange={(e) => {searchAction(e)}} debounce={1000} animated></IonSearchbar>  
        </IonToolbar>
      </IonHeader>}
      {/*<IonFab vertical='top' horizontal='end'>
        <IonFabButton color="tertiary" style={{marginTop: '0', marginRight: '10px'}} size='small'>
          <IonIcon icon={eyeOutline} />
        </IonFabButton>
        <IonFabList side="bottom">
          <IonFabButton>
            <IonIcon icon={logoGithub} />
          </IonFabButton>
          <IonFabButton>
            <IonIcon icon={logoJavascript} />
          </IonFabButton>
          <IonFabButton>
            <IonIcon icon={logoAngular} />
          </IonFabButton>
        </IonFabList>
      </IonFab>*/}
      <IonContent fullscreen>
        {<IonList>
          {songs && songs.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).filter((s: { name: string; content: any[]; }) => ((s.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) || (s && s.content && s.content.find(t => t.text.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)) )).map((song: { id: any; category_id: any; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined) => {
                return (
                  <IonItem key={i} button={true} href={`/songs/${song.id}`} style={{borderLeft: `10px solid ${getCategoryColor(song.category_id)}`}}>
                    <IonLabel>{song.name}</IonLabel>
                  </IonItem>                  
                );
          })}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Search);