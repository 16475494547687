import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { listOutline, musicalNotesOutline, logInOutline, searchOutline, logOutOutline} from 'ionicons/icons';
import Songs from './pages/Songs';
import Playlists from './pages/Playlists';
import Login from './pages/Login';
import API from './api';
import Songadd from './pages/Songadd';
import Playlistadd from './pages/Playlistadd';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import SongDetail from './pages/SongDetail';
import Category from './pages/Category';
import Subcategory from './pages/Subcategory';
import PlaylistDetail from './pages/PlaylistDetail';
import Search from './pages/Search';
import { useLocalStorage } from 'usehooks-ts';
import Logout from './pages/Logout';


setupIonicReact();

const App: React.FC = () => {
  const [userData, setUserData] = useState<any>();
  const [categories, setCategories] = useState<Array<any>>();
  const [subcategories, setSubcategories] = useState<Array<any>>();
  const [songs, setSongs] = useState<Array<any>>();
  const [playlists, setPlaylists] = useState<Array<any>>();
  const [recordings, setRecordings] = useState<Array<any>>();
  const [loggedIn, setLoggedIn] = useLocalStorage('loggedIn', false);
  const [focusMode] = useLocalStorage('focusMode', false);

  useEffect(() => {
    if (userData) console.log(userData);
  }, [userData]);

  useEffect(() => {
    API.get("/get?tablename=categories")
      .then((res: { status: number; data: Array<any> }) => {
          if (res.status === 200) {
            setCategories(res.data.sort((a, b) => a.id - b.id));
          }
        })
        .catch((error: any)=>{
          
        })
    API.get("/get?tablename=subcategories")
      .then((res: { status: number; data: Array<any> }) => {
          if (res.status === 200) {
            setSubcategories(res.data.sort((a, b) => a.view_order - b.view_order));
          }
        })
        .catch((error: any)=>{
          
        })
    API.get("/get?tablename=songs")
      .then((res: { status: number; data: Array<any> }) => {
          if (res.status === 200) {
            setSongs(res.data);
          }
      })
      .catch((error: any)=>{
        
      })
    API.get("/get?tablename=playlists")
      .then((res: { status: number; data: Array<any> }) => {
          if (res.status === 200) {
            setPlaylists(res.data);
          }
      })
      .catch((error: any)=>{
        
      })
    API.get("/get?tablename=recordings")
      .then((res: { status: number; data: Array<any> }) => {
          if (res.status === 200) {
            setRecordings(res.data);
          }
      })
      .catch((error: any)=>{
        
      })
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/songs">
              <Songs categories={categories} />
            </Route>
            <Route exact path="/playlists">
              <Playlists playlists={playlists} />
            </Route>
            <Route exact path="/search">
              <Search categories={categories} songs={songs} />
            </Route>
            <Route exact path="/login">
              <Login setUserData={setUserData}/>
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/">
              <Redirect to="/songs" />
            </Route>
            <Route exact path="/songadd">
              <Songadd categories={categories} subcategories={subcategories} />
            </Route>
            <Route exact path="/playlistadd">
              <Playlistadd />
            </Route>
            <Route path="/songs/:id">
              <SongDetail categories={categories} songs={songs} playlists={playlists} recordings={recordings} />
            </Route>
            <Route path="/categories/:id">
              <Category categories={categories} subcategories={subcategories} songs={songs} />
            </Route>
            <Route path="/subcategories/:id">
              <Subcategory subcategories={subcategories} songs={songs} />
            </Route>
            <Route path="/playlist/:id">
              <PlaylistDetail categories={categories} songs={songs} playlists={playlists} />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom" style={{display: `${focusMode ? "none": "flex"}`}}>
            <IonTabButton tab="songs" href="/songs">
              <IonIcon icon={musicalNotesOutline} />
              <IonLabel>Piesne</IonLabel>
            </IonTabButton>
            <IonTabButton tab="playlists" href="/playlists">
              <IonIcon icon={listOutline} />
              <IonLabel>Playlisty</IonLabel>
            </IonTabButton>
            <IonTabButton tab="search" href="/search">
              <IonIcon icon={searchOutline} />
              <IonLabel>Hľadať</IonLabel>
            </IonTabButton>
            {!loggedIn && <IonTabButton tab="login" href="/login">
              <IonIcon icon={logInOutline} />
              <IonLabel>Admin</IonLabel>
            </IonTabButton>}
            {loggedIn && <IonTabButton tab="logout" href="/logout">
              <IonIcon icon={logOutOutline} />
              <IonLabel>Odhlásiť</IonLabel>
            </IonTabButton>}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
