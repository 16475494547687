import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { personCircle } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonAlert } from '@ionic/react';
import './Login.css';
import API from '../api';
import { useLocalStorage } from 'usehooks-ts';

function validateEmail(email: string) {
  // eslint-disable-next-line no-control-regex
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

interface LoginProps {
  setUserData: any;
}

const Login: React.FC<LoginProps> = ({setUserData}) => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [iserror, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [, setLoggedIn] = useLocalStorage('loggedIn', false);

  const handleLogin = () => {
    if (!email) {
      setMessage("Zadajte email v správnom tvare");
      setIserror(true);
      return;
    }
    if (validateEmail(email) === false) {
      setMessage("Email nie je správne zadaný");
      setIserror(true);
      return;
    }

    if (!password || password.length < 6) {
      setMessage("Zadajte heslo");
      setIserror(true);
      return;
    }

    const loginData = {
      "email": email,
      "password": password
    }

    API.post("/signin", loginData)
    .then(res => {
      if (res.status === 200) {
        setLoggedIn(true);
        setUserData(res.data);
      } else {
        setLoggedIn(false);
      }
      history.push("/");
    })
    .catch(error=>{
      setMessage("Auth failure! Please create an account");
      setIserror(true)
    })
  };

  return (
    <IonPage>
      <IonContent fullscreen className="ion-text-center">
        <div className="xc">
          <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                  isOpen={iserror}
                  onDidDismiss={() => setIserror(false)}
                  header={"Error!"}
                  message={message}
                  buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                  style={{ fontSize: "70px", color: "#0040ff" }}
                  icon={personCircle}
              />
            </IonCol>
          </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                      type="email"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value!)}
                      >
                  </IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Heslo</IonLabel>
                  <IonInput
                    type="password"
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value!)}
                    >
                  </IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={handleLogin}>Login</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;