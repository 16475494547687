import React from 'react';
import { IonContent, IonIcon, IonPage, IonLoading, IonCard, IonCardHeader, IonCardTitle, IonFab, IonFabButton } from '@ionic/react';
import { add } from 'ionicons/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import './Songs.css';
import { useLocalStorage } from 'usehooks-ts';
interface SongsProps extends RouteComponentProps {
  categories?: Array<any>;
}

const Songs: React.FC<SongsProps> = ({categories}) => {
  const [loggedIn] = useLocalStorage('loggedIn', false);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={!categories}
          message={'Chvíľku strpenia...'}
        />
        {categories && categories.map((category, i) => {
              return (
                <IonCard key={i} button={true} style={{backgroundColor: category.color}} href={`/categories/${category.id}`}>
                  <IonCardHeader>
                    <IonCardTitle className="sb-category-name">{category.name}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              );
        })}
        {loggedIn && <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton href="/songadd">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Songs);