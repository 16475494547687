import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import './Category.css';

interface CategoryProps extends RouteComponentProps {
    categories?: any;
    subcategories?: any;
    songs?: any;
}

const Category: React.FC<CategoryProps> = ({match, songs, categories, subcategories}) => {
    const [category, setCategory] = useState<any>(null);
    const [songsFiltered, setSongsFiltered] = useState<Array<any>>();
    const [allSongHaveSubcategory, setAllSongHaveSubcategory] = useState<boolean>(false);

    useEffect(() => {

        let params : any = match.params;
        if (params.id && category === null && categories) {
            setCategory(categories.find((c: { id: number; }) => c.id === Number(params.id)));
        }

        if (songs && songs.length) {
            setSongsFiltered(songs.sort((a: { name: number; },b: { name: number; }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).filter((s: any) => (s.category_id === category.id)));
        }

    }, [songs, category, categories, subcategories, match]);

    useEffect(() => {
        if (songsFiltered) setAllSongHaveSubcategory(_checkSubcategory(songsFiltered));

    }, [songsFiltered]);

    // if all songs of the category belongs into subcategory
    const _checkSubcategory = (s: any) => {
        for (let i = 0; i < s.length ; i++) {
            if (s[i].subcategory_id === null) return false;
        }
        return true;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {category && <IonTitle>{category.name}</IonTitle>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {!allSongHaveSubcategory && category && songsFiltered && songsFiltered.map((song: { id: any; category_id: any; name: boolean | null | undefined; }, i: React.Key) => {
                    return (
                        <IonItem key={i} button={true} href={`/songs/${song.id}`} style={{borderLeft: category && `10px solid ${category.color}`}}>
                            <IonLabel>{song.name}</IonLabel>
                        </IonItem>
                    );
                })}

                {allSongHaveSubcategory && category && subcategories && subcategories.filter((s: any) => (s.category_id === category.id)).map((subcategory: any, i: number) => {
                    return (
                        <IonCard key={i} button={true} style={{backgroundColor: subcategory.color}} href={`/subcategories/${subcategory.id}`}>
                        <IonCardHeader>
                            <IonCardTitle className="sb-category-name">{subcategory.name}</IonCardTitle>
                        </IonCardHeader>
                        </IonCard>
                    );
                })}
            </IonContent>
        </IonPage>
    );
};

export default withRouter(Category);
